import React from 'react';
import { Gallery } from 'react-grid-gallery';
import Tdsa from '../../assets/images/websiteImages/agrostrong/agro.jpg';
import Rad from '../../assets/images/websiteImages/agrostrong/another.jpg';
import wqe from '../../assets/images/websiteImages/agrostrong/askjdkjhsa.jpg'
import fro from '../../assets/images/websiteImages/agrostrong/dadas.jpg';
import uih from '../../assets/images/websiteImages/agrostrong/dksjhdsa.jpg';
import lkfdjh from '../../assets/images/websiteImages/agrostrong/dskajhdsa.jpg';
import naf1 from '../../assets/images/websiteImages/nafuico/nafuico.fc3a55fd1b79fa895716-1024x683.jpg';
import naf2 from '../../assets/images/websiteImages/nafuico/nafuico11.939e0397afd80c04088d-1-1024x683.jpg';
import naf3 from '../../assets/images/websiteImages/nafuico/nafuico12.42746761c7e2d0525b30-600x400.jpg';
import naf4 from '../../assets/images/websiteImages/nafuico/nafuico2.f0cfb03f1d132c86359c-1024x683.jpg';
import naf5 from '../../assets/images/websiteImages/nafuico/nafuico6.5b86f6468a9b7d653508-1024x683.jpg';
import naf6 from '../../assets/images/websiteImages/nafuico/nafuico8.46650581f49a0b7341f5-1024x683.jpg';

const importAllImages = (requireContext) => {
    const images = [];
    requireContext.keys().forEach((key) => {
        const fileName = key.replace('./', ''); // Get the file name
        images.push(requireContext(key)); // Add to the images object
    });
    return images;
};

const images = require.context('../../assets/images/websiteImages/dk', true);

const imageListDk = images.keys().map(image => images(image));

const Assets = importAllImages(require.context('../../assets/images/highlights', false, /\.(png|jpe?g|svg)$/));

const nafuico = [naf1, naf2, naf3, naf4, naf5, naf6];
const sortedNafuico = nafuico.map(x => ({ src: x }));

// const imageListAgro = agroimages.keys().map(image => images(image));

// console.log(imageListAgro);
const sortedDkIMages = Assets.concat(imageListDk).map((x) => ({ src: x }));

const agroimages = [Tdsa, Rad, wqe, fro, uih, lkfdjh];

const sortedAgroImages = (agroimages || []).map((x) => ({ src: x }))

const All = [...sortedDkIMages, ...sortedAgroImages,...sortedNafuico];

export const galleryConstants = [
    {
        gallery: 'All',
        images: <div className='gallery-images'>
            <Gallery images={All} enableImageSelection={false} />
        </div>
    },
    {
        gallery: 'Diaspora Kapita',
        images: <div className='gallery-images'>
            <Gallery images={sortedDkIMages} enableImageSelection={true} />
        </div>
    },
    {
        gallery: 'Tsigiro Usekelo',
        images: <div className='gallery-images'>
            <Gallery images={sortedDkIMages.reverse()} enableImageSelection={false} />
        </div>
    },
    {
        gallery: 'Agrostrong',
        images: <div className='gallery-images'>
            <Gallery images={sortedAgroImages} enableImageSelection={false} />
        </div>
    },
    {
        gallery: 'NAFUICO',
        images: <div className='gallery-images'>
            <Gallery images={sortedNafuico} enableImageSelection={false} />
        </div>
    }
]